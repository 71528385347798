import React from "react";
import { Link } from "gatsby";
import { Box, Typography } from "@smooth-ui/core-sc";

const NybblePage = props => (
  <Box>
    {props.children}
    {renderExamples(props.pageContext)}
    <Link to="/">Keep nybblin'</Link>
  </Box>
);

export default NybblePage;

const renderExamples = ({ frontmatter }) =>
  frontmatter.examples.length > 0
    ? frontmatter.examples.map(({ statement, response }) => {
        return (
          <Box p={2}>
            <Typography display="block" as="em" mb={0}>
              {statement}
            </Typography>
            <Typography display="block" as="em">
              {response}
            </Typography>
          </Box>
        );
      })
    : [];
