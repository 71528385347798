import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";

import { Grid, Row, Col, Box, Typography } from "@smooth-ui/core-sc";

const InfoPage = ({ pageContext, pageProps }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allSitePage {
            edges {
              node {
                path
                context {
                  frontmatter {
                    title
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <Box p={2}>
            <Typography>{pageProps.children}</Typography>
            {pageProps.location.pathname === "/" && (
              <Grid fluid m={0} p={0}>
                <Row>{extractNybbleLinks(data)}</Row>
              </Grid>
            )}
          </Box>
        );
      }}
    />
  );
};

export default InfoPage;

const extractNybbleLinks = data =>
  data.allSitePage.edges
    .map(({ node }) => {
      return node;
    })
    .filter(({ path }) => path.includes("nybbles"))
    .sort((a, b) => a.context.title - b.context.title)
    .map(({ path, context }) => {
      return (
        <Col xs={6} md={3}>
          <Link to={path}> {context.frontmatter.title}</Link>{" "}
        </Col>
      );
    });
