import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

import Header from "../components/header";
import SEO from "../components/seo";
import "../components/layout.css";
import NybblePage from "./nybblePage";
import InfoPage from "./infoPage";
const templateMap = {
  NybblePage,
  InfoPage
};

const CMSBase = ({ pageContext, ...props }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => {
      console.log("HI");
      console.log(data, pageContext, props);
      const Template = templateMap[pageContext.frontmatter.templateKey];
      return (
        <>
          <SEO {...data.site.siteMetadata} keywords={[]} />
          <Header siteTitle={data.site.siteMetadata.title} />
          <div
            style={{
              margin: `0 auto`,
              maxWidth: 960,
              padding: `0px 1.0875rem 1.45rem`,
              paddingTop: 0
            }}
          >
            <h1> {pageContext.frontmatter.title}</h1>
            <Template pageContext={pageContext} pageProps={props}>
              {props.children}
            </Template>
          </div>
        </>
      );
    }}
  />
);

CMSBase.propTypes = {
  children: PropTypes.node.isRequired
};

export default CMSBase;
