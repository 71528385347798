import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { Grid, Row, Col, Typography, Box } from "@smooth-ui/core-sc";
const Header = ({ siteTitle }) => (
  <Grid fluid mb={2} as="header" py="1.45rem">
    <Row alignItems="flex-start">
      <Col />
      <Col
        xs={8}
        md={3}
        backgroundColor="seagreen"
        alignItems="center"
        p={2}
        textAlign="center"
      >
        <Typography variant="h1" fontFamily="Lato" m={0}>
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
              fontWeight: "bold"
            }}
          >
            {siteTitle}
          </Link>
        </Typography>
      </Col>
      <Col display="flex" j>
        <Box ml="auto" pr={2}>
          <Link
            to="/about"
            style={{
              color: `seagreen`,
              textDecoration: `none`
            }}
          >
            About
          </Link>
        </Box>
      </Col>
    </Row>
  </Grid>
);

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
