import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import DefaultLayout from "/opt/build/repo/src/cmsPages/index.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="p" components={components}>{`A compiler is simply a program that takes a bunch of code, and turns it into a form that a computer can execute.`}</MDXTag>
      <MDXTag name="p" components={components}>{`They are useful because they let programmers write code that is more human-readable and understandable, and then convert it to computer-speak.`}</MDXTag>
      <MDXTag name="p" components={components}>{`They also can make the code really efficient by doing things like getting rid of unused files, or other substitution optimizations.`}</MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      